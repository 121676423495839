import { Routes } from '@angular/router';
import { authGuard } from './auth.guard';
const authRoutes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./auth/login/login.component').then((c) => c.LoginComponent),
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import('./auth/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
];

const appRoutes: Routes = [
  {
    path: 'app',
    loadComponent: () =>
      import('./pages/main-app/main-app.component').then(
        (c) => c.MainAppComponent
      ),
    children: [
      {
        path: 'dashboard',
        loadComponent: () =>
          import('./components/dashboard/dashboard.component').then(
            (c) => c.DashboardComponent
          ),
      },
      {
        path: 'appointment',
        loadComponent: () =>
          import('./components/schedule/schedule.component').then(
            (c) => c.ScheduleComponent
          ),
      },
      {
        path: 'tasks',
        loadComponent: () =>
          import('./components/tasks/tasks.component').then(
            (c) => c.TasksComponent
          ),
      },
      {
        path: 'calendar',
        loadComponent: () =>
          import('./components/calender/calender.component').then(
            (c) => c.CalenderComponent
          ),
      },
      {
        path: 'profile',
        loadComponent: () =>
          import('./components/profile/profile.component').then(
            (c) => c.ProfileComponent
          ),
      },
      {
        path: 'signature',
        loadComponent: () =>
          import('./components/signature-pad/signature-pad.component').then(
            (c) => c.SignaturePadComponent
          ),
      },
      {
        path: 'manage-profile',
        loadComponent: () =>
          import('./components/manage-profile/manage-profile.component').then(
            (c) => c.ManageProfileComponent
          ),
      },
      {
        path: 'update-profile-info',
        loadComponent: () =>
          import(
            './components/manage-profile/profile-info/profile-info.component'
          ).then((c) => c.ProfileInfoComponent),
      },
      { path: '', pathMatch: 'full', redirectTo: 'appointment' },
    ],
    canActivate: [authGuard],
  },
];

const caseRoutes: Routes = [
  {
    path: 'cases',
    loadComponent: () =>
      import('../app/pages/case-details/case-details.component').then(
        (c) => c.CaseDetailsComponent
      ),
    children: [
      {
        path: 'patient-details',
        loadComponent: () =>
          import('./components/patient-details/patient-details.component').then(
            (c) => c.PatientDetailsComponent
          ),
      },
      {
        path: 'dictation',
        loadComponent: () =>
          import('./components/dictation/dictation.component').then(
            (c) => c.DictationComponent
          ),
      },
      {
        path: 'documents',
        loadComponent: () =>
          import(
            './components/patient-documents/patient-documents.component'
          ).then((c) => c.PatientDocumentsComponent),
      },
      {
        path: 'patient-tasks',
        loadComponent: () =>
          import('./components/patient-task/patient-task.component').then(
            (c) => c.PatientTaskComponent
          ),
      },
      {
        path: 'appointment-list',
        loadComponent: () => import('./components/patient-appointment-list/patient-appointment-list.component').then((c) => c.PatientAppointmentListComponent)
      },
    ],
  },
];

const fallbackRoute: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: '**', redirectTo: 'login' },
];

export const routes: Routes = [
  ...authRoutes,
  ...appRoutes,
  ...caseRoutes,
  ...fallbackRoute,
];
